export default class Brush3 {

  constructor(p5) {
    this.p5 = p5;

    const properties = {
      // Steps Properties
      numSteps: 8,

      // Size Properties
      size: 15,
      radius: 15,

      // Rotation Properties
      rotate: true,
      angle: 10,

      // Opacity Property
      opacity: 100,

      // Fill Properties
      fillActive: true,
      fillColor: "#ffffff",

      // Border Properties
      borderActive: true,
      borderColor: "#000000",
      borderWidth: 2
    };

    Object.assign(this, properties);
  }

  setP5Instance(p5) {
    this.p5 = p5;
  }

  touched(x, y) {
    this.update(x, y);
  }

  update(x, y) {
    const p5 = this.p5;

    if (this.rotate) {
      this.angle = this.angle === 360 ? 0 : this.angle + 1;
    }

    p5.ellipseMode(p5.CENTER);
    p5.rectMode(p5.CENTER);

    if (this.fillActive) {
      let fColor = p5.color(this.fillColor);
      fColor.setAlpha(p5.map(this.opacity, 0, 100, 0, 255));
      p5.fill(fColor);
    } else {
      p5.noFill();
    }

    if (this.borderActive) {
      let bColor = p5.color(this.borderColor);
      bColor.setAlpha(p5.map(this.opacity, 0, 100, 0, 255));
      p5.stroke(bColor);
      p5.strokeWeight(this.borderWidth);
    } else {
      p5.noStroke();
    }

    let stepAngle = 360 / this.numSteps;
    for (let a = this.angle; a < this.angle + 360; a += stepAngle) {
      let xoff = p5.cos(p5.radians(a)) * this.radius;
      let yoff = p5.sin(p5.radians(a)) * this.radius;
      p5.ellipse(x + xoff, y + yoff, this.size, this.size);
    }
  }
}