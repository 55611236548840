export default class Brush2 {

  constructor(p5) {
    this.p5 = p5;

    const properties = {

      // Size Properties
      minSize: 1,
      maxSize: 30,

      // Opacity Property
      opacity: 50,

      // Fill Properties
      fillActive: true,
      fillColor: "#ffffff",
      fillColorRandomize: false,

      // Border Properties
      borderActive: true,
      borderColor: "#00a1ff",
      minBorderWidth: 1,
      maxBorderWidth: 8,

      // Color Palettes
      colorPalette: 'Janoff Rainbow',
      colors: ['#34BE2D', '#FFB400', '#FF7A00', '#F41E34', '#A2359C', '#00A0E2'],
    };

    Object.assign(this, properties);

    this.colorIndex = 0;
  }

  setP5Instance(p5) {
    this.p5 = p5;
  }

  touched(x, y) {
    this.update(x, y);
  }

  update(x, y) {
    const p5 = this.p5;

    p5.ellipseMode(p5.CENTER);
    p5.rectMode(p5.CENTER);

    if (this.fillActive) {
      
      let fColor = p5.color(this.fillColor);
      if(this.fillColorRandomize) {
          // Interpolate between colors for smooth transitions
          let color1 = p5.color(this.colors[Math.floor(this.colorIndex) % this.colors.length]);
          let color2 = p5.color(this.colors[(Math.floor(this.colorIndex) + 1) % this.colors.length]);
          let lerpAmount = this.colorIndex % 1;
          let currentColor = p5.lerpColor(color1, color2, lerpAmount);
          this.colorIndex += 0.01;

          fColor = p5.color(currentColor);
      }

      fColor.setAlpha(p5.map(this.opacity, 0, 100, 0, 255));
      p5.fill(fColor);
    } else {
      p5.noFill();
    }

    if (this.borderActive) {
      let bColor = p5.color(this.borderColor);
      bColor.setAlpha(p5.map(this.opacity, 0, 100, 0, 255));
      p5.stroke(bColor);

      let size = p5.map(p5.random(0, 1), 0, 1, this.minBorderWidth, this.maxBorderWidth);
      p5.strokeWeight(size);
    } else {
      p5.noStroke();
    }

    let size = p5.map(p5.random(0, 1), 0, 1, this.minSize, this.maxSize);
    p5.ellipse(x, y, size, size);
  }
}