import React, { createContext, useCallback, useRef } from 'react';

export const CanvasContext = createContext();

const CanvasContextProvider = ({ children }) => {

  const canvasHistory = useRef([]);
  const historyIndex = useRef(-1);

  const saveCanvasState = useCallback((p5) => {
    const currentState = p5.get();
    const newCanvasHistory = canvasHistory.current.slice(0, historyIndex.current + 1);
    newCanvasHistory.push(currentState);
    canvasHistory.current = newCanvasHistory;
    historyIndex.current = newCanvasHistory.length - 1;
  }, []);

  const undoCanvas = useCallback((p5) => {
    if (historyIndex.current > 0) {
      historyIndex.current -= 1;
      p5.image(canvasHistory.current[historyIndex.current], 0, 0);
    }
  }, []);

  const redoCanvas = useCallback((p5) => {
    if (historyIndex.current < canvasHistory.current.length - 1) {
      historyIndex.current += 1;
      p5.image(canvasHistory.current[historyIndex.current], 0, 0);
    }
  }, []);

  const clearCanvas = useCallback((p5) => {
  if (p5) {
    p5.clear();

    const backgroundColor = Math.random() > 0.5 ? 255 : 0;
    p5.background(backgroundColor);
  }
}, []);


  const saveCanvasToFile = useCallback((p5) => {
    const date = new Date();
    const timestamp = date.toISOString().replace(/[:.]/g, "-");
    const filename = `canvas-${timestamp}.png`;
    p5.save(filename);
  }, []);

  return (
    <CanvasContext.Provider value={{clearCanvas, saveCanvasState, undoCanvas, redoCanvas, saveCanvasToFile}}>
    {children}
    </CanvasContext.Provider>
  )
};

export default CanvasContextProvider;
