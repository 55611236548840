import './App.css';
import React, { useState, useRef, useEffect, useContext } from 'react';

import CanvasContainer from './components/CanvasContainer.js';
import BrushPropertiesSidebar from './components/BrushPropertiesSidebar.js';
import BrushEditorModal from './components/BrushEditorModal.js';

import { BrushContext } from './contexts/BrushContext';
import { CanvasContext } from './contexts/CanvasContext';

import { ConfigProvider, theme } from "antd";
import { Layout, Menu, Button, Tooltip, Select, Row, Col } from 'antd';
import { 
  PlusCircleOutlined,
  DownloadOutlined, 
  UndoOutlined, 
  RedoOutlined, 
  DeleteOutlined, 
  CodeOutlined
} from '@ant-design/icons';

const { darkAlgorithm } = theme;
const { Header, Content, Sider } = Layout;

function App() {
  const { currentBrush, brushes, selectBrush } = useContext(BrushContext);
  const { clearCanvas, saveCanvasToFile, undoCanvas, redoCanvas } = useContext(CanvasContext);

  const [selectedKey, setSelectedKey] = useState(null);

  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedEditBrush, setSelectedEditBrush] = useState(false);

  const p5Ref = useRef(null);

  useEffect(() => {
    selectBrush(selectedKey);
  }, [selectedKey]);

  useEffect(() => {
    if(currentBrush) { //todo: not sure how this doesn't cause a endless loop
      setSelectedKey(currentBrush.key);
    }
  }, [currentBrush]);

  const handleNewBrushClick = () => {
    setSelectedEditBrush(false);
    setIsEditModalVisible(true);
  };

  const handleEditBrushClick = () => {
    setSelectedEditBrush(true);
    setIsEditModalVisible(true);
  };

  const handleModalOk = () => {
    setIsEditModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsEditModalVisible(false);
  };

  const handleSaveCanvas = () => {
     saveCanvasToFile(p5Ref.current);
  };

  const handleClearCanvas = () => {
    clearCanvas(p5Ref.current);
  };

  const handleUndoCanvas = () => {
    undoCanvas(p5Ref.current);
  };

  const handleRedoCanvas = () => {
    redoCanvas(p5Ref.current);
  };

  return (
    <ConfigProvider theme={{ algorithm: darkAlgorithm }}>
      <Layout style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'none' }}>
        <Row>
          <span className="rainbow-text" style={{ color: '#434343', marginBottom: '24px', fontWeight: '900' }}>GENATO</span>
        </Row>
        <Row gutter={[0, 0]}>
          <Col style={{ maxHeight: '500px', width: '270px', background: 'none', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '8px', marginRight: '16px' }}>
            <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row', gap: '8px' }}>
              <Select
                style={{ width: '100%' }}
                size="large"
                value={selectedKey}
                onChange={(value) => {
                  if (value === '0') {
                    handleNewBrushClick();
                  } else {
                    setSelectedKey(value);
                  }
                }}
              >
                {brushes.map((menuItem) => (
                  <Select.Option key={menuItem.key} value={menuItem.key}>
                    {menuItem.name}
                  </Select.Option>
                ))}
                <Select.Option key="0" value="0">
                  <span style={{}}> <PlusCircleOutlined /> New Brush </span>
                </Select.Option>
              </Select>
              <Tooltip title="Edit Brush"><Button type="default" size="large" icon={<CodeOutlined />} onClick={handleEditBrushClick}></Button></Tooltip>
            </div>

            <BrushPropertiesSidebar brush={currentBrush} />
          </Col>

          <Col>
            <Content style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CanvasContainer currentBrush={currentBrush} p5Ref={p5Ref} />
            </Content>
          </Col>

          <Col>
            <div style={{ background: 'none', display: 'flex', flexDirection: 'column', gap: '8px', marginLeft: '8px' }}>
              <Tooltip title="Save" placement="right"><Button type="text" size="default" icon={<DownloadOutlined />} onClick={handleSaveCanvas}></Button></Tooltip>
              <Tooltip title="Clear" placement="right"><Button type="text" size="default" icon={<DeleteOutlined />} onClick={handleClearCanvas}></Button></Tooltip>
              <Tooltip title="Undo" placement="right"><Button type="text" size="default" icon={<UndoOutlined />} onClick={handleUndoCanvas}></Button></Tooltip>
              <Tooltip title="Redo" placement="right"><Button type="text" size="default" icon={<RedoOutlined />} onClick={handleRedoCanvas}></Button></Tooltip>
            </div>
          </Col>
        </Row>

        <BrushEditorModal
          modalVisible={isEditModalVisible}
          handleModalOk={handleModalOk}
          handleModalCancel={handleModalCancel}
          existingBrush={selectedEditBrush ? currentBrush : null}
        />
      </Layout>
    </ConfigProvider>
  );
}

export default App;
