export default class Brush1 {

  constructor(p5) {
    this.p5 = p5;

    const properties = {
      // Fill Properties
      fillActive: true,
      fillColor: "#32a852",
      fillOpacity: 100,
      fillColorRandomize: false,

      // Border Properties
      borderActive: true,
      borderColor: "#000000",
      borderWeight: 2,
      borderOpacity: 50,
      borderColorRandomize: false,

      // Size Properties
      width: 20,
      height: 20,

      // Angle Properties
      angle: 90,
      angleRandomize: false,

      // Steps Properties
      stepCount: 8,
      stepGap: 0,

      // Color Palettes
      colorPalette: 'Janoff Rainbow',
      colors: ['#34BE2D', '#FFB400', '#FF7A00', '#F41E34', '#A2359C', '#00A0E2'],
    };

    Object.assign(this, properties);

    this.colorIndex = 0;
  }

  setP5Instance(p5) {
    this.p5 = p5;
  }

  touched(x, y) {
    this.update(x, y);
  }

  update(x, y) {
    const p5 = this.p5;

    let rectX = x;
    let rectY = y;
    let offsetX = 0;
    let offsetY = 0;
    let rotationDegrees = 0;

    for (let i = 0; i < this.stepCount; i++) {
      // Set direction
      let angle = this.angleRandomize ? Math.random() * 360 : this.angle; // angle should be in degrees
      let radian = angle * (Math.PI / 180); // convert angle to radians

      let dX = Math.cos(radian);
      let dY = Math.sin(radian);

      // Draw rect
      p5.push();
      if (this.fillActive) {
        let fColor = p5.color(this.fillColor);
        if(this.fillColorRandomize) {
            // Interpolate between colors for smooth transitions
            let color1 = p5.color(this.colors[Math.floor(this.colorIndex) % this.colors.length]);
            let color2 = p5.color(this.colors[(Math.floor(this.colorIndex) + 1) % this.colors.length]);
            let lerpAmount = this.colorIndex % 1;
            let currentColor = p5.lerpColor(color1, color2, lerpAmount);
            this.colorIndex += 0.01;

            fColor = p5.color(currentColor);
        }

        // const fColor = this.fillColorRandomize ? p5.color(p5.random(255), p5.random(255), p5.random(255)) : p5.color(this.fillColor);
        fColor.setAlpha(p5.map(this.fillOpacity, 0, 100, 0, 255));
        p5.fill(fColor);
      } else {
        p5.noFill();
      }

      if (this.borderActive) {
        const bColor = this.borderColorRandomize ? p5.color(p5.random(255), p5.random(255), p5.random(255)) : p5.color(this.borderColor);
        bColor.setAlpha(p5.map(this.borderOpacity, 0, 100, 0, 255));
        p5.stroke(bColor);
        p5.strokeWeight(this.borderWeight);
      } else {
        p5.noStroke();
      }
      p5.rectMode(p5.CENTER);
      p5.translate(rectX + offsetX, rectY + offsetY);
      p5.rotate(p5.radians(rotationDegrees));
      p5.rect(0, 0, this.width, this.height);
      p5.pop();

      // Move next position
      offsetX += dX * (this.width + this.stepGap);
      offsetY += dY * (this.height + this.stepGap);
      rotationDegrees += p5.map(p5.random(0, 1), 0, 1, 0, 20);
    }
  }
}