import React, { useRef, useEffect, useMemo } from 'react';
import { ReactP5Wrapper } from "@p5-wrapper/react";

const canvasWidth = 500;
const canvasHeight = 500;

function CanvasContainer({ currentBrush, p5Ref }) {

	const brushRef = useRef();

	let mousePressedInsideCanvas = false;

	useEffect(() => {
    brushRef.current = currentBrush;
  }, [currentBrush]);

  const sketch = useMemo(() => {
    return (p5) => {
			p5Ref.current = p5;

      p5.setup = () => {
        p5.createCanvas(canvasWidth, canvasHeight);
        p5.background(0);
      };

      p5.mouseDragged = () => {
        drawWithBrush(p5);
      };

      p5.mousePressed = () => {
        drawWithBrush(p5);
      };

      p5.mouseReleased = () => {
        mousePressedInsideCanvas = false;
      };

      const drawWithBrush = (p5) => {
        const brush = brushRef.current;
        if (brush && brush.draw) {
          if (mousePressedInsideCanvas || (p5.mouseX > 0 && p5.mouseX < p5.width && p5.mouseY > 0 && p5.mouseY < p5.height)) {
          	mousePressedInsideCanvas = true;
            brush.draw(p5);
          }
        }
      }
    };
  }, []);

	return (
    <div style={{ borderRadius: '8px', overflow: 'hidden', width: {canvasWidth}, height: {canvasHeight} }}>
    {/* <div style={{ border:'1px solid #d9d9d9', borderRadius: '8px', overflow: 'hidden', width: {canvasWidth}, height: {canvasHeight} }}> */}
      <ReactP5Wrapper sketch={sketch} />      
    </div>
  );
}

export default CanvasContainer;
