export default class Brush4 {

  constructor(p5) {
    this.p5 = p5;

    const properties = {
      // Shape
      shape: "Text",
      text: "genato",

      // Size
      size: 60,
      sizeRandom: true,
      sizeMin: 20,
      sizeMax: 80,

      // Fill
      fill: true,
      fillOpacity: 100,
      fillColor: "#2985b7",
      fillColorRandom: true,

      // Border
      border: true,
      borderOpacity: 100,
      borderColor: "#ffffff",
      borderColorRandom: false,
      borderWidth: 8,

      // Rotation
      rotate: false,
      rotateAngle: 0,
      rotateIncrement: true,
      rotateIncrementAngle: 1,
      rotateAngleRandom: false,
      rotateAngleMin: 0,
      rotateAngleMax: 360,
    };

    Object.assign(this, properties);
  }

  setP5Instance(p5) {
    this.p5 = p5;
  }

  touched(x, y) {
    this.update(x, y);
  }

  update(x, y) {
    const p5 = this.p5;

    // Size
    const size = this.sizeRandom ? p5.map(p5.random(), 0, 1, this.sizeMin, this.sizeMax) : this.size;

    // Fill
    if (this.fill) {
      const fColor = this.fillColorRandom ? p5.color(p5.random(255), p5.random(255), p5.random(255)) : p5.color(this.fillColor);
      fColor.setAlpha(p5.map(this.fillOpacity, 0, 100, 0, 255));
      p5.fill(fColor);
    } else {
      p5.noFill();
    }

    // Border
    if (this.border) {
      const bColor = this.borderColorRandom ? p5.color(p5.random(255), p5.random(255), p5.random(255)) : p5.color(this.borderColor);
      bColor.setAlpha(p5.map(this.borderOpacity, 0, 100, 0, 255));
      p5.stroke(bColor);

      p5.strokeWeight(this.borderWidth);
    } else {
      p5.noStroke();
    }

    p5.push();
    p5.translate(x, y);

    // Rotation
    if (this.rotate) {
      const angle = this.rotateAngleRandom ? p5.map(p5.random(), 0, 1, this.rotateAngleMin, this.rotateAngleMax) : this.rotateAngle;
      p5.rotate(p5.radians(angle));
      if (this.rotateIncrement) {
        this.rotateAngle = (this.rotateAngle + this.rotateIncrementAngle) % 360;
      }
    }

    // Shape
    if (this.shape === 'Oval') {
      p5.ellipseMode(p5.CENTER);
      p5.ellipse(0, 0, size, size);
    } else if (this.shape === 'Rectangle') {
      p5.rectMode(p5.CENTER);
      p5.rect(0, 0, size, size);
    } else if (this.shape === 'Text') {
      p5.rectMode(p5.CENTER);
      p5.textFont('Helvetica');
      p5.textSize(size);
      p5.text(this.text, 0, 0);
    }
    p5.pop();
  }
}