import React, { useState, useEffect, useContext, useRef } from 'react';
import { Pane } from 'tweakpane';
import { Layout, Button } from 'antd';
import { BrushContext } from '../contexts/BrushContext';
import BrushEditorModal from './BrushEditorModal.js'

const { Sider } = Layout;

const colorPalettes = {
  'Janoff Rainbow': ['#34BE2D', '#FFB400', '#FF7A00', '#F41E34', '#A2359C', '#00A0E2'],
  'Vintage': ['#F38181', '#FCE38A', '#EAFFD0', '#95E1D3'],
  'Desert': ['#884A39', '#C38154', '#FFC26F', '#F9E0BB'],
  'Neon': ['#000000', '#FFC600', '#5800FF', '#E900FF'],
  'Moody': ['#0A2647', '#144272', '#205295', '#2C74B3'],
  'Material Design': ['#F44336', '#E91E63', '#9C27B0', '#673AB7', '#3F51B5'],
  'Flat UI': ['#2C3E50', '#E74C3C', '#ECF0F1', '#3498DB', '#2980B9'],
  'Solarized': ['#002B36', '#073642', '#586E75', '#839496', '#93A1A1'],
  'Monokai': ['#272822', '#F92672', '#66D9EF', '#A6E22E', '#FD971F'],
  'Pastel': ['#FFC3A0', '#FFD700', '#8A2BE2', '#20B2AA', '#FF6347'],
  'Ocean': ['#1A535C', '#4ECDC4', '#F7FFF7', '#FF6B6B', '#FFE66D'],
  'Sunset': ['#FFC3A0', '#FF5733', '#C70039', '#900C3F', '#581845'],
  'Forest': ['#264653', '#2A9D8F', '#E9C46A', '#F4A261', '#E76F51'],
  'Retro': ['#5F939A', '#F4C095', '#F2A057', '#D63230', '#D9BF77'],
};

const systemFonts = [
  'Arial',
  'Verdana',
  'Helvetica',
  'Tahoma',
  'Trebuchet MS',
  'Times New Roman',
  'Georgia',
  'Garamond',
  'Courier New',
  'Brush Script MT'
];

function BrushPropertiesSidebar() {
  const { currentBrush } = useContext(BrushContext);

  const paneRef = useRef(null);

  useEffect(() => {
    if (!currentBrush) return;

    const handlePaneChange = (propName, propValue) => {
      if (propName === 'colorPalette') {

      	currentBrush.brush[propName] = propValue;

	      const selectedPalette = colorPalettes[propValue];
      	currentBrush.brush['colors'] = selectedPalette;

      } else {

      	currentBrush.brush[propName] = propValue;

      }
    };

    // Add the pane to the page
    if (!paneRef.current) {
      paneRef.current = new Pane({
        title: 'Brush Properties',
        container: document.getElementById('propertiesContainer'),
      });
    }

    const pane = paneRef.current;

    const addInputToPane = (propName, propValue) => {
      let inputConfig;

      if (propName === 'textFont') {

	      inputConfig = {
	        type: 'select',
	        options: systemFonts.reduce((acc, font) => {
	          acc[font] = font;
	          return acc;
	        }, {})
	      };

      } else if (propName === 'shape') {

      	const shapes = [
	        'Text',
	        'Rectangle',
	        'Oval'
	      ];

	      inputConfig = {
	        type: 'select',
	        options: shapes.reduce((acc, shape) => {
			      acc[shape] = shape;
			      return acc;
			    }, {})
	      };

      } else if (propName === 'colorPalette') {

	      inputConfig = {
	        type: 'select',
	        options: Object.keys(colorPalettes).reduce((acc, paletteName) => {
	            acc[paletteName] = paletteName;
	            return acc;
	        }, {})
		    };

      } else if (propName.toLowerCase().includes('opacity')) {

      	inputConfig = { 
	        	type: 'slider', 
	        	min: 0,
	        	max: 100
	        };

      } else { // if there is not a hardcoded property, automatically detect the type of property

      	if (typeof propValue === 'number') {
	        inputConfig = { 
	        	type: 'slider', 
	        	min: 0,
	        	max: 1000
	        };
	      } else if (typeof propValue === 'boolean') {
	        inputConfig = { 
	        	type: 'checkbox' 
	        };
	      } else if (typeof propValue === 'string' && !propValue.startsWith('#')) {
	        inputConfig = { 
	        	type: 'color', 
	        	format: 'hex' 
	        };
	      } else if (typeof propValue === 'string' && propValue.startsWith('#')) {
	        inputConfig = { 
	        	type: 'color', 
	        	format: 'hex' 
	        };
	      } else { // need to add an array for options (ie. up/down/left/right)
	        return;
	      }

      }

      try {      	
      	let params = {[propName]: propValue };

				const input = pane.addInput(params, propName, inputConfig);

				input.on('change', (ev) => {
					handlePaneChange(ev.presetKey, ev.value);
  			});

      } catch (error) {
        console.error(`Error adding input for property '${propName}':`, error);
      }
    };

    for (const propName in currentBrush.brush) {
      addInputToPane(propName, currentBrush.brush[propName]);
    }

    return () => {
      if (paneRef.current) {
        paneRef.current.dispose();
        paneRef.current = null;
      }
    };
  }, [currentBrush]);

  return (
    <div style={{ width: '100%', maxHeight: '100vh', overflowY: 'scroll' }}>
      {currentBrush ? 
        <div id="propertiesContainer"></div> :
        <div>No brush selected</div>
      }
    </div>
  );
}

export default BrushPropertiesSidebar;
