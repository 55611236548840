export default class Brush6 {
  
  constructor(p5) {
    this.p5 = p5;

    const properties = {
      // Wave Properties
      waveFrequency: 0.1,
      waveAmplitude: 20,
      minLineWidth: 1,
      maxLineWidth: 3,
      
      // Opacity Property
      opacity: 80,
      
      // Color Palettes
      colorPalette: 'Janoff Rainbow',
      colors: ['#34BE2D', '#FFB400', '#FF7A00', '#F41E34', '#A2359C', '#00A0E2'],
    };

    Object.assign(this, properties);

    this.noiseOffset = 2;
    this.colorIndex = 0;
  }

  setP5Instance(p5) {
    this.p5 = p5;
  }

  touched(x, y) {
    this.update(x, y);
  }

  update(x, y) {
    const p5 = this.p5;

    // Do not fill shapes
    p5.noFill();

    // Loop over several lines to be drawn around the point
    for (let i = -3; i <= 3; i++) {
      let xOffset = i * 5;

      // Interpolate between colors for smooth transitions
      let color1 = p5.color(this.colors[Math.floor(this.colorIndex) % this.colors.length]);
      let color2 = p5.color(this.colors[(Math.floor(this.colorIndex) + 1) % this.colors.length]);
      let lerpAmount = this.colorIndex % 1;
      let currentColor = p5.lerpColor(color1, color2, lerpAmount);
      this.colorIndex += 0.01;

      // Get a color from the palette
      p5.stroke(currentColor);

      // Set the line width based on noise
      let lineWidth = p5.map(p5.noise(this.noiseOffset), 0, 1, this.minLineWidth, this.maxLineWidth);
      p5.strokeWeight(lineWidth);

      // Begin a shape
      p5.beginShape();

      // Draw the wave pattern
      for (let j = -100; j <= 100; j += 5) {
        let xWave = x + xOffset + j;
        let yWave = y + i * 3 + this.waveAmplitude * p5.sin(this.waveFrequency * (j + xOffset) + this.noiseOffset);
        p5.vertex(xWave, yWave);
      }

      // End the shape
      p5.endShape();
    }

    // Increment the noise offset
    this.noiseOffset += 0.1;
  }
}