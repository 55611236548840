export default class Brush5 {
  
  constructor(p5) {
    this.p5 = p5;
    
    const properties = {
      // Opacity Property
      opacity: 80,
      // Size Properties
      minSize: 20,
      maxSize: 150,
      // Fill Properties
      fillActive: true,
      fillColor: "#FF0000",
      // Border Properties
      borderActive: true,
      borderColor: "#0000FF",
      minBorderWidth: 1,
      maxBorderWidth: 3,

      noiseOffset: 2
    };

    Object.assign(this, properties);
  }

  setP5Instance(p5) {
    this.p5 = p5;
  }

  touched(x, y) {
    this.update(x, y);
  }

  update(x, y) {
    const p5 = this.p5;
    
    if (this.fillActive) {
      let fColor = p5.color(this.fillColor);
      fColor.setAlpha(p5.map(this.opacity, 0, 100, 0, 255));
      p5.fill(fColor);
    } else {
      p5.noFill();
    }

    if (this.borderActive) {
      let bColor = p5.color(this.borderColor);
      bColor.setAlpha(p5.map(this.opacity, 0, 100, 0, 255));
      p5.stroke(bColor);
      let strokeWidth = p5.map(p5.noise(x * 0.01, y * 0.01), 0, 1, this.minBorderWidth, this.maxBorderWidth);
      p5.strokeWeight(strokeWidth);
    } else {
      p5.noStroke();
    }

    // Drawing the non-perfect circle as a blob
    p5.beginShape();
    const numberOfVertices = 100;
    for (let i = 0; i < numberOfVertices; i++) {
      let angle = p5.map(i, 0, numberOfVertices, 0, p5.TWO_PI);
      let r = p5.map(p5.noise(this.noiseOffset + Math.cos(angle), this.noiseOffset + Math.sin(angle)), 0, 1, this.minSize, this.maxSize);
      let blobX = x + r * Math.cos(angle);
      let blobY = y + r * Math.sin(angle);
      p5.vertex(blobX, blobY);
    }
    p5.endShape(p5.CLOSE);

    this.noiseOffset += 0.1;
  }
}